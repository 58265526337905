import React, { useRef, useEffect, useState } from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Head/Head"
import { Link, graphql } from "gatsby"
import * as TermsStyles from "./TermsPage.module.scss"
import RichTextRenderer from "../components/RichTextRenderer/RichTextRenderer"
import { ChevronRightIcon } from "@heroicons/react/solid"

export const query = graphql`
  query {
    allContentfulPage(
      filter: {
        contentful_id: {
          in: [
            "6qf2pzaFxZsVDnHvKdHjHc"
            "6D1AQfESbOrvzpi4mrwPt6"
            "Pj3wKROaz25ofrs8gZ3wg"
          ]
        }
      }
    ) {
      nodes {
        title
        slug
        pageSections {
          sections {
            description {
              raw
            }
          }
        }
      }
    }
  }
`

const TermsOfServicePage = ({ data }) => {
  const termsRef = useRef()
  const cookiesRef = useRef()
  const privacyRef = useRef()

  const {
    allContentfulPage: { nodes: sections },
  } = data

  const [active, setActive] = useState("terms")

  useEffect(() => {
    if (window && window.location) {
      const hash = window.location.hash
      switch (hash) {
        case "#!/terms":
          handleScroll(termsRef)
          break
        case "#!/privacy":
          handleScroll(privacyRef)
          break
        case "#!/cookies":
          handleScroll(cookiesRef)
          break
      }
    }
  })

  const handleScroll = ref => {
    if (ref && ref.current) {
      switch (ref.current.id) {
        case "#!/terms":
          setActive("terms")
          break
        case "#!/privacy":
          setActive("privacy")
          break
        case "#!/cookies":
          setActive("cookies")
          break
      }
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  return (
    <Layout>
      <Head title="Terms of service | SciPlay"></Head>
      <div className={TermsStyles.container}>
        <div className={TermsStyles.menuContainer}>
          <div className={TermsStyles.menu}>
            <Link
              to="/terms-of-service#!/terms"
              onClick={() => handleScroll(termsRef)}
              className={active == "terms" ? TermsStyles.active : ""}
            >
              <ChevronRightIcon width={20} height={20} />
              Terms
            </Link>
            <Link
              to="/terms-of-service#!/privacy"
              onClick={() => handleScroll(privacyRef)}
              className={active == "privacy" ? TermsStyles.active : ""}
            >
              <ChevronRightIcon width={20} height={20} />
              Privacy
            </Link>
            <Link
              to="/terms-of-service#!/cookies"
              onClick={() => handleScroll(cookiesRef)}
              className={active == "cookies" ? TermsStyles.active : ""}
            >
              <ChevronRightIcon width={20} height={20} />
              Cookies
            </Link>
          </div>
        </div>
        <div className={TermsStyles.content}>
          {sections.map(section => {
            let sectionId
            let ref
            switch (section.slug) {
              case "terms-of-service":
                ref = termsRef
                sectionId = "#!/terms"
                break

              case "cookies":
                ref = cookiesRef
                sectionId = "#!/cookies"
                break

              case "privacy-policy":
                ref = privacyRef
                sectionId = "#!/privacy"
                break
            }

            return (
              <div id={sectionId} ref={ref} className={TermsStyles.section}>
                <h2>{section.title}</h2>
                <RichTextRenderer
                  content={section.pageSections.sections[0].description}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default TermsOfServicePage
